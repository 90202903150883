import { Outlet, ShouldRevalidateFunction } from '@remix-run/react';
import { LoaderFunctionArgs } from '@remix-run/cloudflare';
import { tenant_require } from '~/tenants/common/util.server';
import { remix_title_merge } from '~/lib/remix';
import Full from '~/components/layout/Full';
import { useTenant } from '~/tenants/common/TenantContextProvider';

export async function loader(args: LoaderFunctionArgs) {
  return {
    tenantName: tenant_require(args).name,
  };
}

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export const meta = remix_title_merge<typeof loader>((data) => data.tenantName);

export default function () {
  const tenant = useTenant();

  return (
    <Full>
      <div className="flex max-sm:flex-col h-full">
        <div className="flex items-col items-end sm:items-center justify-center sm:px-8 lg:px-16 xl:px-32 max-sm:py-8 sm:flex-1">
          <img src={tenant.logoVector ?? tenant.logoRaster} className="max-w-64 max-sm:max-h-20" />
        </div>
        <div className="bg-white flex flex-col items-col items-center sm:justify-center p-8 lg:px-16 xl:px-32 flex-1">
          <div className="max-w-md w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </Full>
  );
}
